import React from "react";
import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "./Game.css";
//import Resume from "../images/resumeDummy.png";

import Terminal, {
  ColorMode,
  TerminalInput,
  TerminalOutput,
} from "react-terminal-ui";

function simulateLoading(returnFunc) { }

const Game3 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";

  let date = new Date();
  let startTimer = 600;

  const [first, setFirst] = useState(true)

  const [isGoalAchieved, setIsGoalAchieved] = useState(false); // Placeholder for goal achievement
  const [timer, setTimer] = useState(startTimer); //10 minutes timer in seconds
  const [showNextRoundButton, setShowNextRoundButton] = useState(false);

  let roundNumber = location.state.roundNumber;
  let currentRoundNumber = location.state.roundNumber;
  const [totalPoints, setTotalPoints] = useState(0);
  const [vulnNumber, setVulnNumber] = useState(0);

  let difficultyConditionChosen = location.state.difficultyConditionChosen;
  let colorConditionChosen = location.state.colorConditionChosen;
  let roundNumberAssignedSet = location.state.newRoundNumberAssignedSet;
  let conditionCounterSet = location.state.conditionCounterSet;
  //console.log(taskQA);

  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");
  const [useremail, setUseremail] = useState("");
  const [points, setPoints] = useState(0); // Initialize points with 0
  //point problem is here
  //const [totalPoints, setTotalPoints] = useState(totalPointsFromRound);


  useEffect(() => {
  const changeTotalPoints = async () => {

    console.log("New Total Points Value: " + totalPoints);
    // DATABASE UPDATE FOR TOTAL POINTS
    try {
      const additionDate = {
        createdDate : String(date.toLocaleDateString('en-US', {timeZone: 'America/Denver'})),
        createdTime : String(date.toLocaleTimeString('en-US', {timeZone: 'America/Denver'})),
      };
      const { data } = await axios.post(
        `${apiUrl}/totalpoints`, // Adjust the endpoint as needed
        { username: username, totalPoints: totalPoints,
          ...additionDate,
         },
        { withCredentials: true }
      );
      const { success, message } = data;
      if (success) {
        console.log("Success: updating points", message);
      } else {
        console.log("Error:", message);
      }
    } catch (error) {
      console.log("An error occured while updating total points:", error);
    }
  }; 
  changeTotalPoints();
}, [totalPoints]);


  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ''; // For legacy browsers
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.history.pushState(null, null, window.location.href);

    const handlePopState = () => {
      window.history.pushState(null, null, window.location.href);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    const randomNumberVulnHighlight = (min = 1, max = 5) => {
      if (min > max) {
        throw new Error('Min should be less than or equal to Max');
      }
      let num = Math.floor(Math.random() * (max - min + 1)) + min;
      return num;
    };

    const getTotalPoints = async (user) => {
      try {
        console.log("Sending username:", user);
        const { data } = await axios.post(
          `${apiUrl}/gettotalpoints`, // Adjust the endpoint as needed
          { username: user },
          { withCredentials: true }
        );
        const { success, message, totalPoints } = data;
        if (success) {
          console.log("Success:", message);
          setTotalPoints(totalPoints);
        } else {
          console.log("Error:", message);
        }
      } catch (error) {
        console.log("An error occured while retrieving total points:", error);
      }
    };

    const verifyCookie = async () => {
      /* if (!cookies.token) {
      navigate("/login");
      return; // Exit early if no cookie
    } */

      try {
        const { data } = await axios.post(
          `${apiUrl}/ver`,
          {},
          { withCredentials: true }
        );
        const { status, user, email } = data;
        if (status) {
          setUsername(user);
          setUseremail(email);
          getTotalPoints(user);
          setVulnNumber(randomNumberVulnHighlight(1, 5));
          toast(`Hello ${user}`, {
            position: "top-right",
          });
        } else {
          removeCookie("token");
          navigate("/");
        }
      } catch (error) {
        console.error("Verification failed:", error);
        removeCookie("token");
        navigate("/login");
      }
    };

    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer == 60) {
          alert("One Minute Left");
        }
        if (prevTimer <= 0) {
          clearInterval(countdown); // Corrected to stop at 0
          goToRound(); // Navigate to the next round
          //setShowNextRoundButton(true); // Show the next round button
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(countdown);
  }, []); // Assuming setTimer and setShowNextRoundButton are stable, no dependencies are needed here

  /*
  //  should replace this with  actual goal checking logic
  useEffect(() => {
    // Simulate a goal being achieved after some operation or condition is met
    // For demonstration, we'll just set it to true directly
    setIsGoalAchieved(true);
    setShowNextRoundButton(true); // Show the next round button when the goal is achieved
  }, [isGoalAchieved]); // Depend on the actual condition that signifies goal achievement
*/

  const Logout = () => {
    removeCookie("token");
    navigate("/signup");
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`; // Formats time as MM:SS
  };

  // Function to log command to the backend
  const logCommand = async (
    command,
    timeLeft,
    timeSpent,
    commandSuccess,
    lastRecon,
    exploitLocation,
    exploitPort,
    exploitID,
    exploitUsed,
    exploitLikelihood,
    exploitColor,
    attackSuccess,
    outsideOfSystem,
    system,
    previousLocation,
    nextLocation,
    task1Type,
    task2Type,
    taskType,
    taskPwdCrackDifficulty,
    taskPwdResetDifficulty,
    taskPwdCrackSuccess,
    taskPwdResetSuccess,
    taskPwdCrackFail,
    taskPwdResetFail,
    taskPwdCrackAttempts,
    taskPwdResetAttempts,
    kickedOut,
    currentPoints,
    totalPoints
  ) => {
    try {
      let createdDate = String(date.toLocaleDateString('en-US', {timeZone: 'America/Denver'}));
      let createdTime = String(date.toLocaleTimeString('en-US', {timeZone: 'America/Denver'}));
      let congruent = "Random";

      await axios.post(`${apiUrl}/api/logcommand`, {
        username,
        roundNumber,
        difficultyConditionChosen,
        colorConditionChosen,
        congruent,
        command,
        timeLeft,
        timeSpent,
        commandSuccess,
        lastRecon,
        exploitLocation,
        exploitPort,
        exploitID,
        exploitUsed,
        exploitLikelihood,
        exploitColor,
        attackSuccess,
        outsideOfSystem,
        system,
        previousLocation,
        nextLocation,
        task1Type,
        task2Type,
        taskType,
        taskPwdCrackDifficulty,
        taskPwdResetDifficulty,
        taskPwdCrackSuccess,
        taskPwdResetSuccess,
        taskPwdCrackFail,
        taskPwdResetFail,
        taskPwdCrackAttempts,
        taskPwdResetAttempts,
        kickedOut,
        currentPoints,
        totalPoints,
        createdDate,
        createdTime,
      });
    } catch (error) {
      console.error("Error logging command:", error);
    }
  };

  const [inputtedCommandsList, setInputtedCommandsList] = useState([]);
  const [trackCommandList, setTrackCommandList] = useState(0);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const handleKeyUp = (event) => {
      if (event.key === "ArrowUp") {
        // console.log("Arrow Up Clicked");
        // console.log("inputted commands: ", inputtedCommandsList);
        // console.log("command list tracker: ", trackCommandList);
        // console.log(
        //   "show previous terminal command: ",
        //   inputtedCommandsList[trackCommandList]
        // );
        if (trackCommandList > 0) {
          setTrackCommandList(trackCommandList - 1);
          setInputValue(inputtedCommandsList[trackCommandList]);
        } else if (trackCommandList === 0) {
          setInputValue(inputtedCommandsList[trackCommandList]);
        }
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "ArrowDown") {
        // console.log("Arrow Down Clicked");
        // console.log("inputted commands: ", inputtedCommandsList);
        // console.log("command list tracker: ", trackCommandList + 1);
        // console.log(
        //   "show next terminal command: ",
        //   inputtedCommandsList[trackCommandList + 1]
        // );
        if (trackCommandList < inputtedCommandsList.length - 1) {
          setTrackCommandList(trackCommandList + 1);
          setInputValue(inputtedCommandsList[trackCommandList + 1]);
        } else if (trackCommandList === inputtedCommandsList.length - 1) {
          setInputValue(inputtedCommandsList[trackCommandList + 1]);
        }
      }
    };

    // Attach the global event listener when the component mounts
    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  //Loading Mechanism Helper
  const [isLoading, setLoading] = useState(false);

  //Function to handle terminal input
  const handleTerminalInput = (input) => {
    setInputtedCommandsList([...inputtedCommandsList, input.trim()]);
    setTrackCommandList(inputtedCommandsList.length);
    setInputValue("");

    let data = username + "\\" + terminalPrompt.join("\\") + "> " + input;

    // Add user input to terminal data
    setTerminalLineData((prev) => [
      ...prev,
      <TerminalOutput>
        <div className="terminal-output">{data}</div>
      </TerminalOutput>,
    ]);

    try {
      if (outsideSystem) {
        if (input.trim() === "finishtimer") {
          setTimer(3);
          setTotalPoints(totalPoints + 53);
          setPoints(points + 3);
          //changeTotalPoints();
        } else if (input.trim() === "nmap -sL all") {
          //LOADING MECHANISM
          setLoading(true);
          addTerminalText("Scanning the Network");
          loadingString = "Scanning the Network";
          const intervID = setInterval(updateLoadingString, 200); //Run updateLoadingString every second
          setTimeout(() => {
            clearInterval(intervID); //Stop the interval
            removePrevText(); //Remove loading message
            setLoading(false);
            listSystems(input); //Run Command
          }, 1000); //Seconds of Loading
        } else if (input.trim().startsWith("use_exploit ")) {
          const nameOfExploit = input.trim().split(" ")[1];
          const nameOfSystem = input.trim().split(" ")[2];
          const WAIT_TIME = 1000;

          //LOADING MECHANISM
          setLoading(true);
          addTerminalText("Exploiting");
          loadingString = "Exploiting";
          const intervID = setInterval(updateLoadingString, 200); //Run updateLoadingString every second
          setTimeout(() => {
            clearInterval(intervID); //Stop the interval
            removePrevText(); //Remove loading message
            setLoading(false);
            exploit(nameOfExploit, nameOfSystem, input); //RUN THE EXPLOITTTT
          }, 1000); //Seconds of Loading
        } else if (input.trim().startsWith("nmap ")) {
          const systemName = input.trim().split(" ")[1];
          const startTime = performance.now();

          //LOADING MECHANISM
          addTerminalText("Starting Nmap 6.47 ( http://nmap.org )");
          setLoading(true);
          addTerminalText("Scanning Ports and Vulnerabilities");
          loadingString = "Scanning Ports and Vulnerabilities";
          const intervID = setInterval(updateLoadingString, 200); //Run updateLoadingString every second
          setTimeout(() => {
            clearInterval(intervID); //Stop the interval
            removePrevText(); //Remove loading message
            setLoading(false);
            if (nmapCommand(systemName, input)) {
              addTerminalText("Nmap scan report for " + systemName);
              addTerminalText("Showing open ports");
              showVulnerabilitiesTable(systemName, input); //Run Command
              const duration = (performance.now() - startTime).toFixed(3);
              addTerminalText(
                "Nmap done: 1 IP Address (1 host up) scanned in " +
                duration +
                " ms"
              );
            } else {
              showVulnerabilitiesTable(systemName, input); //Run Command
            }
          }, 1000); //Seconds of Loading
        } else if (input.trim() === "help") {
          // helpCommand();
          showHelpTable(input);
        } else {
          /*
        else if (input.trim() === "p") {
          //Choice Portion of Task will gain 50 points while No choice will get 100 points
          if (currentRoundNumber === 1 || currentRoundNumber === 3) {
            setPoints(points + 50);
            changeTotalPoints(totalPoints + 50);
          } else {
            setPoints(points + 100);
            changeTotalPoints(totalPoints + 100);
          }
        } */
          failedCommand();

          logCommand(
            input,
            String(timer),
            String(startTimer - timer),
            false,
            lastSystemRecon,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            outsideSystem,
            currentSystemName,
            null,
            null,
            task1README,
            task2README,
            taskREADME,
            taskCrackDifficulty,
            taskResetDifficulty,
            null,
            null,
            null,
            null,
            String(crackCount),
            String(resetCount),
            false,
            String(points),
            String(totalPoints)
          );
        }
      } else if (!outsideSystem && !inTaskReset && !inTaskCrack) {
        if (input.trim().startsWith("cd ..")) {
          cdCommandReverse(input);
        } else if (input.trim().startsWith("cd ")) {
          const systemName = input.trim().split(" ")[1];
          cdCommand(systemName, input);
        } else if (input.trim().startsWith("cat ")) {
          const fileName = input.trim().split(" ")[1];
          const fileType = input.trim().split(".")[1];
          catCommand(input, fileName, fileType);
        } else if (input.trim().startsWith("cp ")) {
          const sourceFile = input.trim().split(" ")[1];
          const destinationFile = input.trim().split(" ")[2];
          cpCommand(input, sourceFile, destinationFile);
        } else if (input.trim() === "ls") {
          lsCommand(input);
        } else if (input.trim().startsWith("unshadow ")) {
          const passwdFile = input.trim().split(" ")[1];
          const shadowFile = input.trim().split(" ")[2];
          const greaterThanOperator = input.trim().split(" ")[3];
          const passwordsFile = input.trim().split(" ")[4];
          if (
            passwdFile === "passwd.txt" &&
            shadowFile === "shadow.txt" &&
            greaterThanOperator === ">" &&
            passwordsFile === "passwords.txt"
          ) {
            unshadowCommand(input);
          } else {
            failedCommand();
            logCommand(
              input,
              String(timer),
              String(startTimer - timer),
              false,
              lastSystemRecon,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              outsideSystem,
              currentSystemName,
              null,
              null,
              task1README,
              task2README,
              taskREADME,
              taskCrackDifficulty,
              taskResetDifficulty,
              null,
              null,
              null,
              null,
              String(crackCount),
              String(resetCount),
              false,
              String(points),
              String(totalPoints)
            );
          }
        } else if (input.trim().startsWith("john ")) {
          const flag = input.trim().split(" ")[1];
          if (flag === "--show") {
            const passwordsFile = input.trim().split(" ")[2];
            if (passwordsFile === "passwords.txt") {
              jtrShowCommand(input);
            } else {
              addTerminalText("Please enter the correct passwords file");
            }
          } else if (flag === "--single") {
            const passwordsFile = input.trim().split(" ")[2];
            console.log(flag);
            console.log(passwordsFile);
            if (passwordsFile === "passwords.txt") {
              //LOADING MECHANISM
              const startTime = performance.now();
              addTerminalText("Using default input encoding: UTF-8");
              addTerminalText("Loaded passwords with salts");
              setLoading(true);
              addTerminalText("Cracking Passwords");
              loadingString = "Cracking Passwords";
              const intervID = setInterval(updateLoadingString, 400); //Run updateLoadingString every second
              setTimeout(() => {
                clearInterval(intervID); //Stop the interval
                removePrevText(); //Remove loading message
                setLoading(false);
                jtrSingleCommand(input); //Run Command
                const duration = (performance.now() - startTime).toFixed(3);
                addTerminalText("Session completed in " + duration + " ms");
              }, 2000); //Seconds of Loading
            }
          } else if (flag.trim().split("=")[0] === "--wordlist") {
            const wordlistFile = flag.trim().split("=")[1];
            console.log(wordlistFile);
            const passwordsFile = input.trim().split(" ")[2];
            console.log(passwordsFile);
            if (passwordsFile === "passwords.txt") {
              //LOADING MECHANISM
              setLoading(true);
              addTerminalText("Cracking Passwords");
              loadingString = "Cracking Passwords";
              const intervID = setInterval(updateLoadingString, 800); //Run updateLoadingString every second
              setTimeout(() => {
                clearInterval(intervID); //Stop the interval
                removePrevText(); //Remove loading message
                setLoading(false);
                jtrWordlistCommand(wordlistFile, input); //Run Command
              }, 4000); //Seconds of Loading
            } else {
              addTerminalText("Please enter the correct passwords file");
            }
          } else {
            console.log("This command is not working");
            failedCommand();
            logCommand(
              input,
              String(timer),
              String(startTimer - timer),
              false,
              lastSystemRecon,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              outsideSystem,
              currentSystemName,
              null,
              null,
              task1README,
              task2README,
              taskREADME,
              taskCrackDifficulty,
              taskResetDifficulty,
              null,
              null,
              null,
              null,
              String(crackCount),
              String(resetCount),
              false,
              String(points),
              String(totalPoints)
            );
          }
        } else if (input.trim() === "logout") {
          logoutSystem(input);
        } else if (input.trim().startsWith("pwd_reset ")) {
          let person = input.trim().split(" ")[1];
          pwdResetCommand(input, person);
        } else if (input.trim().startsWith("pwd_crack ")) {
          let person = input.trim().split(" ")[1];
          pwdCrackCommand(input, person);
        } else if (input.trim() === "help") {
          //helpCommand(input);
          showHelpTable(input);
        }

        //testing commands which will be removed from game
        /*
        else if (input.trim() === "u") {
          unshadowChecker();
        } else if (input.trim() === "jShow") {
          jtrShowChecker();
        } else if (input.trim() === "jS") {
          jtrSingleChecker();
        } else if (input.trim() === "jW") {
          jtrWordlistChecker();
        } else if (input.trim().startsWith("lc")) {
          linuxFolderChecker();
        }
        */
        else {
          failedCommand();
          logCommand(
            input,
            String(timer),
            String(startTimer - timer),
            false,
            lastSystemRecon,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            outsideSystem,
            currentSystemName,
            null,
            null,
            task1README,
            task2README,
            taskREADME,
            taskCrackDifficulty,
            taskResetDifficulty,
            null,
            null,
            null,
            null,
            String(crackCount),
            String(resetCount),
            false,
            String(points),
            String(totalPoints)
          );
        }
      } else if (!outsideSystem && inTaskReset && !inTaskCrack) {
        console.log("We are in task reset questions");
        let nextCount = resetCount + 1;
        let checker = input === resetAnswers[resetCount];
        console.log(checker);

        setResetCount(nextCount);
        if (nextCount > 2) {
          setInTaskReset(false);
          setResetCount(0);
          console.log("Back to game");
          if (checker && resetCorrect) {
            //Choice Portion of Task will gain 50 points while No choice will get 100 points
            if (difficultyConditionChosen === "Choice") {
              setPoints(points + 50);
              setTotalPoints(totalPoints + 50);
              //changeTotalPoints();
              addTerminalText(
                "Password Reset Task was successful! You have gained 50 points from resetting the password! Type 'logout' to hack other systems"
              );

              logCommand(
                input,
                String(timer),
                String(startTimer - timer),
                true,
                lastSystemRecon,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                outsideSystem,
                currentSystemName,
                null,
                null,
                task1README,
                task2README,
                taskREADME,
                taskCrackDifficulty,
                taskResetDifficulty,
                null,
                true,
                null,
                false,
                String(crackCount),
                String(resetCount + 1),
                false,
                String(points),
                String(totalPoints)
              );
            } else if (difficultyConditionChosen === "No Choice") {
              setPoints(points + 100);
              setTotalPoints(totalPoints + 100);
              //changeTotalPoints();
              addTerminalText(
                "Password Reset Task was successful! You have gained 100 points from resetting the password! Type 'logout' to hack other systems"
              );

              logCommand(
                input,
                String(timer),
                String(startTimer - timer),
                true,
                lastSystemRecon,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                outsideSystem,
                currentSystemName,
                null,
                null,
                task1README,
                task2README,
                taskREADME,
                taskCrackDifficulty,
                taskResetDifficulty,
                null,
                true,
                null,
                false,
                String(crackCount),
                String(resetCount + 1),
                false,
                String(points),
                String(totalPoints)
              );
            }
            setTaskQA((draft) => {
              draft =
                draft[currentSystemName].pwd_reset_qa[taskResetPerson]
                  .attackSuccess;
              draft[0] = true;
            });
          }
          //
          else {
            let noAttemptsLeft =
              taskQA[currentSystemName].pwd_reset_qa[taskResetPerson]
                .attemptsLeft[0] -
              1 ===
              0;
            if (noAttemptsLeft) {
              logCommand(
                input,
                String(timer),
                String(startTimer - timer),
                true,
                lastSystemRecon,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                outsideSystem,
                null,
                currentSystemName,
                null,
                task1README,
                task2README,
                taskREADME,
                null,
                null,
                null,
                null,
                null,
                null,
                String(crackCount),
                String(resetCount),
                true,
                String(points),
                String(totalPoints)
              );

              addTerminalText(
                "You have been kicked out of the system. You lose 50 points for failing the task"
              );
              setBlockedSystem(...blockedSystem, currentSystemName);
              kickoutSystem(input);
              setPoints(points - 50);
              setTotalPoints(totalPoints - 50);
              //changeTotalPoints();
            } else {
              addTerminalText("FAILED password reset");
              setTaskQA((draft) => {
                draft =
                  draft[currentSystemName].pwd_reset_qa[taskResetPerson]
                    .attemptsLeft;
                draft[0] = draft[0] - 1;
              });

              logCommand(
                input,
                String(timer),
                String(startTimer - timer),
                true,
                lastSystemRecon,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                outsideSystem,
                currentSystemName,
                null,
                null,
                task1README,
                task2README,
                taskREADME,
                taskCrackDifficulty,
                taskResetDifficulty,
                null,
                false,
                null,
                true,
                String(crackCount),
                String(resetCount + 1),
                false,
                String(points),
                String(totalPoints)
              );
            }
          }
        } else {
          if (checker) {
            console.log("Correct Answer");
            logCommand(
              input,
              String(timer),
              String(startTimer - timer),
              true,
              lastSystemRecon,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              outsideSystem,
              currentSystemName,
              null,
              null,
              task1README,
              task2README,
              taskREADME,
              taskCrackDifficulty,
              taskResetDifficulty,
              null,
              null,
              null,
              null,
              String(crackCount),
              String(resetCount),
              false,
              String(points),
              String(totalPoints)
            );
          } else if (!checker) {
            setResetCorrect(false);
            console.log("Answer not correct");
            logCommand(
              input,
              String(timer),
              String(startTimer - timer),
              true,
              lastSystemRecon,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              outsideSystem,
              currentSystemName,
              null,
              null,
              task1README,
              task2README,
              taskREADME,
              taskCrackDifficulty,
              taskResetDifficulty,
              null,
              null,
              null,
              null,
              String(crackCount),
              String(resetCount),
              false,
              String(points),
              String(totalPoints)
            );
          }
          addTerminalText(resetQuestions[nextCount]);
        }
      } else if (!outsideSystem && !inTaskReset && inTaskCrack) {
        console.log("We are in task crack questions");
        let nextCount = crackCount + 1;
        let checker = input === crackAnswers[crackCount];
        console.log(checker);

        setCrackCount(nextCount);
        if (nextCount > 0) {
          setInTaskCrack(false);
          setCrackCount(0);
          console.log("Back to the game");
          if (checker && crackCorrect) {
            //Choice Portion of Task will gain 50 points while No choice will get 100 points
            if (difficultyConditionChosen === "Choice") {
              setPoints(points + 50);
              setTotalPoints(totalPoints + 50);
              //changeTotalPoints();
              addTerminalText(
                "Password Crack Task was successful! You have gained 50 points from cracking the password! Type 'logout' to hack other systems"
              );

              logCommand(
                input,
                String(timer),
                String(startTimer - timer),
                true,
                lastSystemRecon,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                outsideSystem,
                currentSystemName,
                null,
                null,
                task1README,
                task2README,
                taskREADME,
                taskCrackDifficulty,
                taskResetDifficulty,
                true,
                null,
                false,
                null,
                String(crackCount + 1),
                String(resetCount),
                false,
                String(points),
                String(totalPoints)
              );
            } else if (difficultyConditionChosen === "No Choice") {
              setPoints(points + 100);
              setTotalPoints(totalPoints + 100);
              //changeTotalPoints();
              addTerminalText(
                "Password Crack Task was successful! You have gained 100 points from cracking the password! Type 'logout' to hack other systems"
              );

              logCommand(
                input,
                String(timer),
                String(startTimer - timer),
                true,
                lastSystemRecon,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                outsideSystem,
                currentSystemName,
                null,
                null,
                task1README,
                task2README,
                taskREADME,
                taskCrackDifficulty,
                taskResetDifficulty,
                true,
                null,
                false,
                null,
                String(crackCount + 1),
                String(resetCount),
                false,
                String(points),
                String(totalPoints)
              );
            }
            setTaskQA((draft) => {
              draft =
                draft[currentSystemName].pwd_crack_qa[taskCrackPerson]
                  .attackSuccess;
              draft[0] = true;
            });
          } else {
            let noAttemptsLeft =
              taskQA[currentSystemName].pwd_crack_qa[taskCrackPerson]
                .attemptsLeft[0] -
              1 ===
              0;
            if (noAttemptsLeft) {
              addTerminalText(
                "You have been kicked out of the system. You lose 50 points for failing the task"
              );
              setBlockedSystem(...blockedSystem, currentSystemName);
              kickoutSystem(input);
              setPoints(points - 50);
              setTotalPoints(totalPoints - 50);
              //changeTotalPoints();
              logCommand(
                input,
                String(timer),
                String(startTimer - timer),
                true,
                lastSystemRecon,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                outsideSystem,
                currentSystemName,
                null,
                null,
                task1README,
                task2README,
                taskREADME,
                taskCrackDifficulty,
                taskResetDifficulty,
                null,
                null,
                null,
                null,
                String(crackCount),
                String(resetCount),
                false,
                String(points),
                String(totalPoints)
              );
            } else {
              addTerminalText("FAILED password crack");
              setTaskQA((draft) => {
                draft =
                  draft[currentSystemName].pwd_crack_qa[taskCrackPerson]
                    .attemptsLeft;
                draft[0] = draft[0] - 1;
              });
              logCommand(
                input,
                String(timer),
                String(startTimer - timer),
                true,
                lastSystemRecon,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                outsideSystem,
                currentSystemName,
                null,
                null,
                task1README,
                task2README,
                taskREADME,
                taskCrackDifficulty,
                taskResetDifficulty,
                null,
                null,
                null,
                null,
                String(crackCount),
                String(resetCount),
                false,
                String(points),
                String(totalPoints)
              );
            }
          }
        } else {
          if (checker) {
            console.log("Correct Answer");
            logCommand(
              input,
              String(timer),
              String(startTimer - timer),
              true,
              lastSystemRecon,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              outsideSystem,
              currentSystemName,
              null,
              null,
              task1README,
              task2README,
              taskREADME,
              taskCrackDifficulty,
              taskResetDifficulty,
              null,
              null,
              null,
              null,
              String(crackCount),
              String(resetCount),
              false,
              String(points),
              String(totalPoints)
            );
          } else if (!checker) {
            setCrackCorrect(false);
            console.log("Wrong answer");
            logCommand(
              input,
              String(timer),
              String(startTimer - timer),
              true,
              lastSystemRecon,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              outsideSystem,
              currentSystemName,
              null,
              null,
              task1README,
              task2README,
              taskREADME,
              taskCrackDifficulty,
              taskResetDifficulty,
              null,
              null,
              null,
              null,
              String(crackCount),
              String(resetCount),
              false,
              String(points),
              String(totalPoints)
            );
          }
          addTerminalText(crackQuestions[nextCount]);
        }
      } else if (input.trim() === "help") {
        //helpCommand(input);
        showHelpTable(input);
      } else {
        //Handle other commands or show error
        failedCommand();
        logCommand(
          input,
          String(timer),
          String(startTimer - timer),
          false,
          lastSystemRecon,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          outsideSystem,
          currentSystemName,
          null,
          null,
          task1README,
          task2README,
          taskREADME,
          taskCrackDifficulty,
          taskResetDifficulty,
          null,
          null,
          null,
          null,
          String(crackCount),
          String(resetCount),
          false,
          String(points),
          String(totalPoints)
        );
      }
    } catch (error) {
      addTerminalText("There is an error", error);
      console.log("ERROR:", error);
      failedCommand();
      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        false,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount),
        false,
        String(points),
        String(totalPoints)
      );
    }
  };

  const addTerminalText = (terminalText) => {
    setTerminalLineData((prev) => [
      ...prev,
      <TerminalOutput>
        <div className="terminal-output">{terminalText}</div>
      </TerminalOutput>,
    ]);
  };

  //Testing Loading Mech
  const removePrevText = () => {
    setTerminalLineData((prev) => {
      const newArray = [...prev];
      newArray.pop();
      return newArray;
    });
  };

  //Loading stuff
  let loadingString = "Loading";
  const updateLoadingString = () => {
    //Remove previous terminal text
    removePrevText();
    loadingString += ".";
    addTerminalText(loadingString);
  };

  //
  const failedCommand = () => {
    addTerminalText(
      "Unknown command. Please run help command for correct command usage"
    );
  };

  const help =
    "> nmap -sL all \n " +
    "List available systems for probing \n" +
    "> nmap [systemName] \n " +
    "Provide a table of vulnerabilities of a system which can be exploited \n" +
    "> use_exploit [exploit] [systemName] \n" +
    "Use one of the exploits listed to hack into the system. Note: might require multiple tries \n" +
    "> ls \n" +
    "Lists files or folders present in current directory \n" +
    "> cd [folderName] \n" +
    "The `cd` command helps to traverse through the folders \n" +
    "> cd .. \n" +
    "Go back to previous folder \n" +
    "> cat [fileName] \n" +
    "Displays the content present in the file \n" +
    "> cp [folder/file location] ./ \n" +
    "copies the folder or file from its original location to its current location";

  const helpCommand = (input) => {
    addTerminalText(help);
    logCommand(
      input,
      String(timer),
      String(startTimer - timer),
      true,
      lastSystemRecon,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      outsideSystem,
      currentSystemName,
      null,
      null,
      task1README,
      task2README,
      taskREADME,
      taskCrackDifficulty,
      taskResetDifficulty,
      null,
      null,
      null,
      null,
      String(crackCount),
      String(resetCount + 1),
      false,
      String(points),
      String(totalPoints)
    );
  };

  const [outsideSystem, setOutsideSystem] = useState(true);
  const [blockedSystem, setBlockedSystem] = useState([]);

  let tasks = location.state.taskQuestionSolution;
  const [taskQA, setTaskQA] = useImmer(tasks);

  const [inTaskReset, setInTaskReset] = useState(false);
  const [resetQuestions, setResetQuestions] = useState([]);
  const [resetAnswers, setResetAnswers] = useState([]);
  const [resetCount, setResetCount] = useState(0);
  const [resetCorrect, setResetCorrect] = useState(true);
  const [taskResetPerson, setTaskResetPerson] = useState("");
  const [taskResetDifficulty, setTaskResetDifficulty] = useState(null);

  const [inTaskCrack, setInTaskCrack] = useState(false);
  const [crackQuestions, setCrackQuestions] = useState([]);
  const [crackAnswers, setCrackAnswers] = useState([]);
  const [crackCount, setCrackCount] = useState(0);
  const [crackCorrect, setCrackCorrect] = useState(true);
  const [taskCrackPerson, setTaskCrackPerson] = useState("");
  const [taskCrackDifficulty, setTaskCrackDifficulty] = useState(null);

  const pwdResetCommand = (input, personName) => {
    let taskReset = taskQA[currentSystemName].pwd_reset_qa[personName];
    let attackSucceeded = Object.values(taskReset["attackSuccess"]);
    //check if pass has already been reset
    if (attackSucceeded[0] === true) {
      addTerminalText("This user's password has been reset");
    } else {
      let taskQuestions = Object.values(taskReset["questions"]);
      let taskAnswers = Object.values(taskReset["answers"]);
      setResetAnswers(taskAnswers);
      setResetQuestions(taskQuestions);
      addTerminalText(taskQuestions[0]);
      setResetCount(0);
      setInTaskReset(true);
      setTaskResetPerson(personName);
      setResetCorrect(true);

      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        true,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount + 1),
        false,
        String(points),
        String(totalPoints)
      );
    }
  };

  const pwdCrackCommand = (input, personName) => {
    let taskCrack = taskQA[currentSystemName].pwd_crack_qa[personName];
    let attackSucceeded = Object.values(taskCrack["attackSuccess"]);
    //check if pass has already been cracked
    if (attackSucceeded[0] === true) {
      addTerminalText("This user's password has been cracked");
    } else {
      let taskQuestion = Object.values(taskCrack["question"]);
      let taskPassword = Object.values(taskCrack["password"]);
      setCrackAnswers(taskPassword);
      setCrackQuestions(taskQuestion);
      addTerminalText(taskQuestion[0]);
      setCrackCount(crackCount);
      setInTaskCrack(true);
      setTaskCrackPerson(personName);
      setCrackCorrect(true);

      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        true,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount + 1),
        false,
        String(points),
        String(totalPoints)
      );
    }
  };

  const linuxFoldersStructure = location.state.lFolderStructure;
  //console.log(Object.entries(linuxFoldersStructure));

  const linuxFolderChecker = () => {
    console.log(linuxFolders);
  };

  const [linuxFolders, setLinuxFolders] = useImmer(linuxFoldersStructure);
  const [systemFolders, setSystemFolders] = useImmer({});
  const [currentSystemName, setCurrentSystemName] = useState("Outside");
  const [folderLocationTracker, setFolderLocationTracker] = useState([]);
  const [folderLocation, setFolderLocation] = useState(systemFolders);
  useEffect(() => {
    systemFolderToFolderLocationSynchronizer();
  }, [systemFolders]);

  const systemFolderToFolderLocationSynchronizer = () => {
    console.log("system folder synchronized with folder location");
    let folderLocationUpdated = systemFolders;
    for (let i = 0; i < folderLocationTracker.length; i++) {
      folderLocationUpdated = folderLocationUpdated[folderLocationTracker[i]];
    }
    setFolderLocation(folderLocationUpdated);
  };

  const linuxFolderToSystemFolderSynchronizer = () => {
    console.log("linux folder synchronized with system folder");
    setLinuxFolders((draft) => {
      draft[currentSystemName] = systemFolders;
    });
  };

  const cpCommand = (input, source, destination) => {
    let syntaxOfSource = false;
    if (source[0] === "/") syntaxOfSource = true;

    let mainDirectory = false;
    if (source === "/") mainDirectory = true;

    let sourceFolderLocation = source.split("/");
    let contentOfFolderOrFile = systemFolders;
    let sourceFileLocation = "";
    let folderORfilePresent = true;

    if (!mainDirectory && syntaxOfSource) {
      for (let i = 1; i < sourceFolderLocation.length; i++) {
        if (
          Object.keys(contentOfFolderOrFile).includes(
            sourceFolderLocation[i]
          ) &&
          folderORfilePresent
        ) {
          contentOfFolderOrFile =
            contentOfFolderOrFile[sourceFolderLocation[i]];
          if (i === sourceFolderLocation.length - 1)
            sourceFileLocation = sourceFolderLocation[i];
        } else {
          folderORfilePresent = false;
        }
      }
    }

    if (syntaxOfSource && folderORfilePresent) {
      let fileInfo = sourceFileLocation.split(".");
      let fileKey = "";
      let fileChecker = false;
      if (fileInfo[1] === "txt" || fileInfo[1] === "png") {
        fileChecker = true;
        fileKey = sourceFileLocation;
      }

      let destinationPath = destination.split("/");
      if (destinationPath[0] === "." && fileChecker) {
        console.log("This is a file");
        setSystemFolders((draft) => {
          for (let i = 0; i < folderLocationTracker.length; i++) {
            draft = draft[folderLocationTracker[i]];
          }
          draft[fileKey] = contentOfFolderOrFile;
        });

        logCommand(
          input,
          String(timer),
          String(startTimer - timer),
          true,
          lastSystemRecon,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          outsideSystem,
          currentSystemName,
          null,
          null,
          task1README,
          task2README,
          taskREADME,
          taskCrackDifficulty,
          taskResetDifficulty,
          null,
          null,
          null,
          null,
          String(crackCount),
          String(resetCount + 1),
          false,
          String(points),
          String(totalPoints)
        );
      } else if (destinationPath[0] === "." && !fileChecker) {
        console.log("it is a folder");
        setSystemFolders((draft) => {
          for (let i = 0; i < folderLocationTracker.length; i++) {
            draft = draft[folderLocationTracker[i]];
          }
          let newFileEntries = Object.entries(contentOfFolderOrFile);
          newFileEntries.map(([key, value]) => {
            draft[key] = value;
          });
        });

        logCommand(
          input,
          String(timer),
          String(startTimer - timer),
          true,
          lastSystemRecon,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          outsideSystem,
          currentSystemName,
          null,
          null,
          task1README,
          task2README,
          taskREADME,
          taskCrackDifficulty,
          taskResetDifficulty,
          null,
          null,
          null,
          null,
          String(crackCount),
          String(resetCount + 1),
          false,
          String(points),
          String(totalPoints)
        );
      }
    } else {
      failedCommand();
      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        false,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount),
        false,
        String(points),
        String(totalPoints)
      );
    }
  };

  const catCommand = (input, nameOfFile, typeOfFile) => {
    //updated cat command
    if (typeOfFile === "png") {
      let imageHolder = Object.values(folderLocation[nameOfFile]).join("");
      setTerminalLineData((prev) => [
        ...prev,
        <TerminalOutput>
          <div className="terminal-output">
            {<img src={imageHolder} height={800} width={600} />}
          </div>
        </TerminalOutput>,
      ]);

      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        true,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount + 1),
        false,
        String(points),
        String(totalPoints)
      );
    } else if (typeOfFile === "txt") {
      let textHolder = Object.values(folderLocation[nameOfFile]).join("");
      setTerminalLineData((prev) => [
        ...prev,
        <TerminalOutput>
          <div className="txt-output">{textHolder}</div>
        </TerminalOutput>,
      ]);

      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        true,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount + 1),
        false,
        String(points),
        String(totalPoints)
      );
    } else {
      failedCommand();
      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        false,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount),
        false,
        String(points),
        String(totalPoints)
      );
    }
  };

  //This function executes the "cd [folderName]" command
  const cdCommand = (folderName, input) => {
    //checks to see if the folder is present under that specific location
    let folderPresent = Object.keys(folderLocation).includes(folderName);
    //checks if it is a file
    let notFile = true;
    //files have extensions like .txt and if it can be split then
    //it will be considered as a file and not a folder
    if (folderName.split(".").length > 1) notFile = false;
    //Sets the condition that if folder is present and it is not a file
    //then cd command will execute
    //IT CHECKS IF FOLDER IN THAT LOCATION IS PRESENT AND IT IS NOT A FILE
    if (folderPresent && notFile) {
      //It changes the current location to the new location
      setFolderLocation(folderLocation[folderName]);
      //It changes the pointer to the current location to the new location
      setFolderLocationTracker([...folderLocationTracker, folderName]);
      //changes the terminal prompt to show the new location
      setTerminalPrompt([...terminalPrompt, folderName]);
      console.log(String(resetCount));
      //If it is the main directory then the data collection will be a
      //little different where previous location and next location will be unique
      if (folderLocationTracker.length === 0) {
        logCommand(
          input,
          String(timer),
          String(startTimer - timer),
          true,
          lastSystemRecon,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          outsideSystem,
          currentSystemName,
          currentSystemName,
          folderName,
          task1README,
          task2README,
          taskREADME,
          taskCrackDifficulty,
          taskResetDifficulty,
          null,
          null,
          null,
          null,
          String(crackCount),
          String(resetCount),
          false,
          String(points),
          String(totalPoints)
        );
        //If it is not the main directory then the previousLocation will
        //be the current location and next location will be the folder name
      } else {
        logCommand(
          input,
          String(timer),
          String(startTimer - timer),
          true,
          lastSystemRecon,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          outsideSystem,
          currentSystemName,
          folderLocationTracker[folderLocationTracker.length - 1],
          folderName,
          task1README,
          task2README,
          taskREADME,
          taskCrackDifficulty,
          taskResetDifficulty,
          null,
          null,
          null,
          null,
          String(crackCount),
          String(resetCount),
          false,
          String(points),
          String(totalPoints)
        );
      }
      //If the checks are not passed then the command will fail
    } else {
      failedCommand();
      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        false,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount),
        false,
        String(points),
        String(totalPoints)
      );
    }
  };

  const cdCommandReverse = (input) => {
    //updated cd .. command
    let startLocation = systemFolders;
    for (let i = 0; i < folderLocationTracker.length - 1; i++) {
      startLocation = startLocation[folderLocationTracker[i]];
    }
    if (folderLocationTracker.length === 1) {
      setFolderLocation(startLocation);
      setFolderLocationTracker(folderLocationTracker.slice(0, -1));
      setTerminalPrompt(terminalPrompt.slice(0, -1));

      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        true,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        folderLocationTracker[folderLocationTracker.length - 1],
        currentSystemName,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount),
        false,
        String(points),
        String(totalPoints)
      );
    } else if (folderLocationTracker.length === 0) {
      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        true,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        currentSystemName,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount),
        false,
        String(points),
        String(totalPoints)
      );
    } else {
      setFolderLocation(startLocation);
      setFolderLocationTracker(folderLocationTracker.slice(0, -1));
      setTerminalPrompt(terminalPrompt.slice(0, -1));

      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        true,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        folderLocationTracker[folderLocationTracker.length - 1],
        folderLocationTracker[folderLocationTracker.length - 2],
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount),
        false,
        String(points),
        String(totalPoints)
      );
    }
  };

  //It executes the "ls"command
  //the command shows all of the children folders or files listed
  //under the current folder location
  const lsCommand = (input) => {
    //updated ls command
    let l = Object.keys(folderLocation);
    setTerminalLineData((prev) => [
      ...prev,
      <TerminalOutput>
        <div className="terminal-output">{l.join("  ")}</div>
      </TerminalOutput>,
    ]);

    logCommand(
      input,
      String(timer),
      String(startTimer - timer),
      true,
      lastSystemRecon,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      outsideSystem,
      currentSystemName,
      null,
      null,
      task1README,
      task2README,
      taskREADME,
      taskCrackDifficulty,
      taskResetDifficulty,
      null,
      null,
      null,
      null,
      String(crackCount),
      String(resetCount),
      false,
      String(points),
      String(totalPoints)
    );
  };

  const listSystems = (input) => {
    const systemsList = Object.keys(linuxFoldersStructure).sort();
    setTerminalLineData((prev) => [
      ...prev,
      <TerminalOutput>
        <div className="terminal-output">{systemsList.join(" ")}</div>
      </TerminalOutput>,
    ]);

    logCommand(
      input,
      String(timer),
      String(startTimer - timer),
      true,
      lastSystemRecon,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      outsideSystem,
      currentSystemName,
      null,
      null,
      task1README,
      task2README,
      taskREADME,
      taskCrackDifficulty,
      taskResetDifficulty,
      null,
      null,
      null,
      null,
      String(crackCount),
      String(resetCount),
      false,
      String(points),
      String(totalPoints)
    );
  };

  const randomNumberGenerator = (min = 0, max = 9) => {
    if (min > max) {
      throw new Error('Min should be less than or equal to Max');
    }
    let num = Math.floor(Math.random() * (max - min + 1)) + min;
    return num;
  };

  const logoutSystem = (input) => {
    linuxFolderToSystemFolderSynchronizer();
    setCurrentSystemName("Outside");
    setSystemFolders({});
    setFolderLocationTracker([]);
    setOutsideSystem(true);
    setTerminalPrompt([]);
    setTask1README(null);
    setTask2README(null);
    setTaskREADME(null);
    setTaskCrackDifficulty(null);
    setTaskResetDifficulty(null);

    logCommand(
      input,
      String(timer),
      String(startTimer - timer),
      true,
      lastSystemRecon,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      true, //outsideOfSystem
      null,
      currentSystemName,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      String(crackCount),
      String(resetCount),
      false,
      String(points),
      String(totalPoints)
    );
  };

  const kickoutSystem = (input) => {
    linuxFolderToSystemFolderSynchronizer();
    setCurrentSystemName("Outside");
    setSystemFolders({});
    setFolderLocationTracker([]);
    setOutsideSystem(true);
    setTerminalPrompt([]);
    setTask1README(null);
    setTask2README(null);
    setTaskREADME(null);
    setTaskCrackDifficulty(null);
    setTaskResetDifficulty(null);

    logCommand(
      input,
      String(timer),
      String(startTimer - timer),
      true,
      lastSystemRecon,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      true,
      null,
      currentSystemName,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      String(crackCount),
      String(resetCount),
      true,
      String(points),
      String(totalPoints)
    );
  };

  const [unshadow, setUnshadow] = useState(false);

  const unshadowCommand = (input) => {
    let fileLists = Object.keys(folderLocation);
    let passwd = false;
    let shadow = false;
    fileLists.map((fileList) => {
      if (fileList === "passwd.txt") passwd = true;
      if (fileList === "shadow.txt") shadow = true;
    });
    if (passwd && shadow) {
      setUnshadow(true);
      setSystemFolders((draft) => {
        for (let i = 0; i < folderLocationTracker.length; i++) {
          draft = draft[folderLocationTracker[i]];
          draft["passwords.txt"] = "Unshadowed Passwordz.txt File";
        }
      });

      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        true,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount + 1),
        false,
        String(points),
        String(totalPoints)
      );
    }
  };

  const unshadowChecker = () => {
    console.log("unshadow checker", unshadow);
  };

  const jtrShowChecker = () => {
    console.log("jtr show checker", jtrShow);
  };

  const jtrSingleChecker = () => {
    console.log("jtr single checker", jtrSingle);
  };

  const jtrWordlistChecker = () => {
    console.log("jtr wordlist checker", jtrWordlist);
  };

  const [jtrSingle, setJtrSingle] = useState(false);
  const [jtrWordlist, setJtrWordlist] = useState(false);
  const [jtrShow, setJtrShow] = useState(false);

  //data collection needs to adddressed but this is not used
  const jtrSingleCommand = (input) => {
    let taskCrackPersonName = Object.keys(
      taskQA[currentSystemName].pwd_crack_qa
    );
    let personPassword =
      taskQA[currentSystemName].pwd_crack_qa[taskCrackPersonName].password;
    if (taskCrackDifficulty === "Medium") {
      if (unshadow) {
        setJtrSingle(true);
        setCrackedPassword(personPassword);
      } else {
        addTerminalText("There is no unshadowed file");
      }
    } else {
      failedCommand();
      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        false,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount),
        false,
        String(points),
        String(totalPoints)
      );
    }
  };

  let wrongPasswords = [
    "147258",
    "simple",
    "bollocks",
    "12345q",
    "marcus",
    "brian",
    "1987",
    "qweasdzxc",
    "drowssap",
    "hahaha",
    "caroline",
    "barbara",
    "dave",
    "viper",
    "drummer",
    "action",
    "einstein",
    "genesis",
    "hello1",
    "scotty",
    "friend",
    "forest",
    "010203",
    "hotrod",
    "google",
    "vanessa",
    "spitfire",
    "badger",
    "maryjane",
    "friday",
    "alaska",
    "1232323q",
    "tester",
    "jester",
    "jake",
    "champion",
    "billy",
    "147852",
    "rock",
    "hawaii",
    "badass",
    "chevy",
    "420420",
    "walker",
    "stephen",
    "eagle1",
    "bill",
    "1986",
    "october",
    "gregory",
    "svetlana",
    "pamela",
    "1984",
    "music",
    "shorty",
    "westside",
    "stanley",
    "diesel",
    "courtney",
    "242424",
    "kevin",
  ];

  const jtrWordlistCommand = (wordlistFileName, input) => {
    let taskCrackPersonName = Object.keys(
      taskQA[currentSystemName].pwd_crack_qa
    );
    let correctWordlist =
      taskQA[currentSystemName].pwd_crack_qa[taskCrackPersonName].wordlist;
    console.log(correctWordlist);
    console.log(wordlistFileName);

    let personPassword =
      taskQA[currentSystemName].pwd_crack_qa[taskCrackPersonName].password;
    if (taskCrackDifficulty === "Hard") {
      console.log("This is working");
      let fileLists = Object.keys(folderLocation);
      let wordlistFound = false;
      fileLists.map((fileList) => {
        if (fileList === wordlistFileName) wordlistFound = true;
      });
      if (unshadow && wordlistFound) {
        setJtrWordlist(true);
        if (correctWordlist[0] === wordlistFileName) {
          console.log("Correct Wordlist selected");
          setCrackedPassword(personPassword[0]);
          logCommand(
            input,
            String(timer),
            String(startTimer - timer),
            true,
            lastSystemRecon,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            outsideSystem,
            currentSystemName,
            null,
            null,
            task1README,
            task2README,
            taskREADME,
            taskCrackDifficulty,
            taskResetDifficulty,
            null,
            null,
            null,
            null,
            String(crackCount),
            String(resetCount),
            false,
            String(points),
            String(totalPoints)
          );
        } else {
          console.log("Wrong wordlist Selected");
          let num = Math.floor(Math.random() * 20);
          setCrackedPassword(wrongPasswords[num]);
          logCommand(
            input,
            String(timer),
            String(startTimer - timer),
            true,
            lastSystemRecon,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            outsideSystem,
            currentSystemName,
            null,
            null,
            task1README,
            task2README,
            taskREADME,
            taskCrackDifficulty,
            taskResetDifficulty,
            null,
            null,
            null,
            null,
            String(crackCount),
            String(resetCount),
            false,
            String(points),
            String(totalPoints)
          );
        }
      } else {
        addTerminalText("There is no unshadowed file");
        logCommand(
          input,
          String(timer),
          String(startTimer - timer),
          false,
          lastSystemRecon,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          outsideSystem,
          currentSystemName,
          null,
          null,
          task1README,
          task2README,
          taskREADME,
          taskCrackDifficulty,
          taskResetDifficulty,
          null,
          null,
          null,
          null,
          String(crackCount),
          String(resetCount),
          false,
          String(points),
          String(totalPoints)
        );
      }
    } else {
      addTerminalText("Something wrong here");
      failedCommand();
      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        false,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount),
        false,
        String(points),
        String(totalPoints)
      );
    }
  };

  const [crackedPassword, setCrackedPassword] = useState(
    "There is no password"
  );

  const jtrShowCommand = (input) => {
    if (unshadow && jtrSingle) {
      setJtrShow(true);
      addTerminalText(crackedPassword);

      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        true,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount + 1),
        false,
        String(points),
        String(totalPoints)
      );
    } else if (unshadow && jtrWordlist) {
      setJtrShow(true);
      addTerminalText(crackedPassword);

      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        true,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount + 1),
        false,
        String(points),
        String(totalPoints)
      );
    }
  };

  //List of the vulnerability headings for the table of vulnerabilities
  const vulnerabilityHeadings = [
    "Port No.",
    "Vulnerability",
    "Exploit Name",
    "Likelihood",
  ];

  const helpHeadings = ["Command", "Description", "Example Usage"];

  const styles = {
    content: {},
  };

  const [task1README, setTask1README] = useState(null);
  const [task2README, setTask2README] = useState(null);
  const [taskREADME, setTaskREADME] = useState(null);

  let task1READMEtxt = "task1README.txt";
  let task2READMEtxt = "task2README.txt";
  let taskREADMEtxt = "taskREADME.txt";

  //use_exploit buffer_overflow System1
  const exploit = (exploitName, systemName, input) => {
    //ProgressBarToDo
    let isBlocked = blockedSystem.includes(systemName);

    if (isBlocked) addTerminalText("You cannot access this system");
    else if (Object.keys(linuxFolders).includes(systemName)) {
      let vulnerabilitesEntries = Object.entries(
        vulnerabilitiesOfSystem[systemName]
      );
      let exploitInformation = [];
      let pwdCrackDifficulty, pwdResetDifficulty;

      //extract location, port, vul name, vul ID and likelihood
      vulnerabilitesEntries.map((vulnerabilitesInfo, index) => {
        if (exploitName === vulnerabilitesInfo[0]) {
          exploitInformation.push(index);
          vulnerabilitesInfo[1].map((vulInfo) => {
            //console.log(vulInfo);
            exploitInformation.push(vulInfo);
          });
        }
      });
      if (exploitInformation[5] === vulnNumber) {
        exploitInformation.push("Yellow");
      } else {
        exploitInformation.push("None");
      }

      let vulnerabilities = Object.keys(vulnerabilitiesOfSystem[systemName]);
      let foundVulnerability = false;
      vulnerabilities.map((vulnerability) => {
        if (vulnerability === exploitName) foundVulnerability = true;
      });
      let successRate = randomNumberGenerator(0, 100);
      if (foundVulnerability === true && successRate >= 26) {
        
        // Chance of Success is about 75%
        setCurrentSystemName(systemName);
        setSystemFolders(linuxFolders[systemName]);
        setOutsideSystem(false);
        addTerminalText("You have gained access to the system");
        setTerminalPrompt([...terminalPrompt, systemName]);
        if (
          Object.keys(linuxFolders[systemName]).includes("task1README.txt") &&
          Object.keys(linuxFolders[systemName]).includes("task2README.txt")
        ) {
          let task1READMEcontent = Object.values(
            linuxFolders[systemName][task1READMEtxt]
          );
          let task2READMEcontent = Object.values(
            linuxFolders[systemName][task2READMEtxt]
          );
          let task1Type = task1READMEcontent[0].split("\r")[0];
          let task2Type = task2READMEcontent[0].split("\r")[0];

          setTask1README(task1Type);
          setTask2README(task2Type);

          let crackTaskPerson = Object.keys(taskQA[systemName].pwd_crack_qa);
          let resetTaskPerson = Object.keys(taskQA[systemName].pwd_reset_qa);

          pwdCrackDifficulty =
            taskQA[systemName].pwd_crack_qa[crackTaskPerson].difficulty[0];

          pwdResetDifficulty =
            taskQA[systemName].pwd_reset_qa[resetTaskPerson].difficulty[0];

          console.log(pwdCrackDifficulty);
          setTaskCrackDifficulty(pwdCrackDifficulty);
          setTaskResetDifficulty(pwdResetDifficulty);
        } else if (
          Object.keys(linuxFolders[systemName]).includes("taskREADME.txt")
        ) {
          let taskREADMEcontent = Object.values(
            linuxFolders[systemName][taskREADMEtxt]
          );
          let taskType = taskREADMEcontent[0].split("\r")[0];
          setTaskREADME(taskType);
          if (taskType === "Password Crack") {
            let crackTaskPerson = Object.keys(taskQA[systemName].pwd_crack_qa);
            pwdCrackDifficulty =
              taskQA[systemName].pwd_crack_qa[crackTaskPerson].difficulty[0];
            setTaskCrackDifficulty(pwdCrackDifficulty);
          } else if (taskType === "Password Reset") {
            let resetTaskPerson = Object.keys(taskQA[systemName].pwd_reset_qa);
            pwdResetDifficulty =
              taskQA[systemName].pwd_reset_qa[resetTaskPerson].difficulty[0];
            setTaskResetDifficulty(pwdResetDifficulty);
          }
        } else {
          addTerminalText("Task Missing in this System");
        }

        logCommand(
          input,
          String(timer),
          String(startTimer - timer),
          true,
          lastSystemRecon,
          exploitInformation[0],
          exploitInformation[1],
          exploitInformation[2],
          exploitInformation[3],
          exploitInformation[4],
          exploitInformation[6],
          true,
          outsideSystem,
          systemName,
          currentSystemName,
          systemName,
          task1README,
          task2README,
          taskREADME,
          taskCrackDifficulty,
          taskResetDifficulty,
          null,
          null,
          null,
          null,
          String(crackCount),
          String(resetCount),
          false,
          String(points),
          String(totalPoints)
        );
      } else {
        addTerminalText("Failed to log in to the system. Please try again");

        logCommand(
          input,
          String(timer),
          String(startTimer - timer),
          true,
          lastSystemRecon,
          exploitInformation[0],
          exploitInformation[1],
          exploitInformation[2],
          exploitInformation[3],
          exploitInformation[4],
          exploitInformation[6],
          false,
          outsideSystem,
          systemName,
          currentSystemName,
          systemName,
          task1README,
          task2README,
          taskREADME,
          taskCrackDifficulty,
          taskResetDifficulty,
          null,
          null,
          null,
          null,
          String(crackCount),
          String(resetCount),
          false,
          String(points),
          String(totalPoints)
        );
      }
    } else {
      failedCommand();
      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        false,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount),
        false,
        String(points),
        String(totalPoints)
      );
    }
  };

  const systemVuls = location.state.systemVulnerabilitiesList;
  const [vulnerabilitiesOfSystem, setVulnerabilitiesOfSystem] =
    useState(systemVuls);

  const helpInformation = {
    ["nmap -sL all"]: ["List available systems for probing", "nmap -sL all"],
    ["nmap"]: [
      "Provide a table of vulnerabilities of a system which can be exploited",
      "nmap [systemName]",
    ],
    ["use_exploit"]: [
      "Use one of the exploits listed to hack into the system. Note: might require multiple tries",
      "use_exploit [exploit] [systemName]",
    ],
    ["ls"]: ["Lists files or folders present in current directory", "ls"],
    ["cd"]: [
      "The `cd` command helps to traverse through the folders",
      "cd [folderName], cd ..",
    ],
    ["cat"]: ["Displays the content present in the file", "cat [fileName]"],
    ["cp"]: [
      "Copies the folder or file from its original location to its current location",
      "cp [folder/file location] ./",
    ],
    ["logout"]: ["Logs out any system being worked on", "logout"]
  };

  const showHelpTable = () => {
    let helpInfos = Object.entries(helpInformation);
    const helpTableMaker = () => {
      return (
        <table className="table">
          <thead>
            <tr>
              {helpHeadings.map((helpHeading) => (
                <th key={helpHeading}>{helpHeading}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {helpInfos.map(([commandName, commandInfo]) => {
              return (
                <tr key={commandName}>
                  <td>{commandName}</td>
                  <td>{commandInfo[0]}</td>
                  <td>{commandInfo[1]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    };

    //Add table to terminal
    setTerminalLineData((prev) => [
      ...prev,
      <TerminalOutput>
        <div className="terminal-output">{helpTableMaker()}</div>
      </TerminalOutput>,
    ]);
  };

  //Checks if nmap command passes the check
  const nmapCommand = (systemName, input) => {
    if (Object.keys(linuxFolders).includes(systemName)) {
      return true;
    } else {
      return false;
    }
  };

  //Constructs a table with vulnerability heading and all the vulnerabilites
  //associated with the specific system
  const showVulnerabilitiesTable = (systemName, input) => {
    if (Object.keys(linuxFolders).includes(systemName)) {
      //lists all of the vulnerabilities present in the system
      let vulnerabilities = Object.entries(vulnerabilitiesOfSystem[systemName]);

      //fucntion to make a table with heading and mapping each of the vulnerabilities
      //under the assigned vulnerability heading
      const tableMaker = () => {
        return (
          <table className="table">
            <thead>
              <tr>
                {vulnerabilityHeadings.map((vulnerabilityHeading) => (
                  <th key={vulnerabilityHeading}>{vulnerabilityHeading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {vulnerabilities.map(
                ([vulnerabilityNumber, vulnerabilityInfo]) => {
                  let tableRow;
                  if (
                    vulnerabilityInfo[4] === vulnNumber &&
                    colorConditionChosen === "Minimum" 
                  ) {
                    tableRow = (
                      <tr key={vulnerabilityNumber}>
                        <td>{vulnerabilityInfo[0]}</td>
                        <td>{vulnerabilityInfo[1]}</td>
                        <td style={{ ...styles.content, color: "yellow" }}>
                          {vulnerabilityInfo[2]}
                        </td>
                        <td>{vulnerabilityInfo[3]}</td>
                      </tr>
                    );
                  } else {
                    tableRow = (
                      <tr key={vulnerabilityNumber}>
                        <td>{vulnerabilityInfo[0]}</td>
                        <td>{vulnerabilityInfo[1]}</td>
                        <td>{vulnerabilityInfo[2]}</td>
                        <td>{vulnerabilityInfo[3]}</td>
                      </tr>
                    );
                  }
                  return tableRow;
                }
              )}
            </tbody>
          </table>
        );
      };
      //adds the table to the terminal data
      setTerminalLineData((prev) => [
        ...prev,
        <TerminalOutput>
          <div className="terminal-output">{tableMaker()}</div>
        </TerminalOutput>,
      ]);

      //needs serious restructuring

      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        true,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        systemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount),
        false,
        String(points),
        String(totalPoints)
      );
      setLastSystemRecon(systemName);
    } else {
      failedCommand();
      logCommand(
        input,
        String(timer),
        String(startTimer - timer),
        false,
        lastSystemRecon,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        outsideSystem,
        currentSystemName,
        null,
        null,
        task1README,
        task2README,
        taskREADME,
        taskCrackDifficulty,
        taskResetDifficulty,
        null,
        null,
        null,
        null,
        String(crackCount),
        String(resetCount),
        false,
        String(points),
        String(totalPoints)
      );
      return false;
    }
  };

  const [lastSystemRecon, setLastSystemRecon] = useState(null);

  const [terminalLineData, setTerminalLineData] = useState([
    <TerminalOutput>
      <div className="terminal-output"></div>
    </TerminalOutput>,
  ]);

  const randomNumberGenerator0or1 = () => {
    let num = Math.floor(Math.random() * 2);
    console.log("rnd num generator", num);
    return num;
  };

  const goToRound = () => {
    if (roundNumber < 4) {

      roundNumber = roundNumber + 1;
      let colorCondition = ["Neutral", "Minimum"];
      let difficultyCondition = ["Choice", "No Choice"];
      colorConditionChosen = colorCondition[randomNumberGenerator0or1()];
      difficultyConditionChosen =
        difficultyCondition[randomNumberGenerator0or1()];

      if (colorConditionChosen === "Neutral") {
        if(conditionCounterSet[0] < 2){
          conditionCounterSet[0] += 1;
        } else {
          colorConditionChosen = "Minimum";
          conditionCounterSet[1] += 1;
        }
      } else {
        if(conditionCounterSet[1] < 2){
          conditionCounterSet[1] += 1;
        } else {
          colorConditionChosen = "Neutral";
          conditionCounterSet[0] += 1;
        }
      }

      if (difficultyConditionChosen === "Choice") {
        if(conditionCounterSet[2] < 2){
          conditionCounterSet[2] += 1;
        } else {
          difficultyConditionChosen = "No Choice";
          conditionCounterSet[3] += 1;
        }
      } else {
        if(conditionCounterSet[3] < 2){
          conditionCounterSet[3] += 1;
        } else {
          difficultyConditionChosen = "No Choice";
          conditionCounterSet[2] += 1;
        }
      }

      navigate("/round", {
        state: {
          roundNumber,
          totalPoints,
          colorConditionChosen,
          difficultyConditionChosen,
          roundNumberAssignedSet,
          conditionCounterSet,
        },
      });
    } else {
      navigate("/postexperiment");
    }
  };

  const [terminalPrompt, setTerminalPrompt] = useState([]);

  const dynamicStyle = {
    color: timer < 60 ? 'red' : '#00ff00',
  };

  return (
    <div>
      <div className="game_page">
        {/* This div is the "Top Bar" */}
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "75px",
            alignItems: "center",
            justifyContent: "center",
            dynamicStyle,
          }}
        >
          {/* Display logout button */}
          {/* <button onClick={Logout}>LOGOUT</button> */}


          {/* Display the timer */}
          <div style={dynamicStyle} className="data-element">Timer: {formatTime(timer)}</div>

          {/* Display our points */}
          <div className="data-element">Points: {points}</div>

          {/* Conditionally render the "Next Round" button */}
          <div className="data-element">Total Points: {totalPoints}</div>
          {/* {showNextRoundButton && (
            <button onClick={goToRound}>Next Round</button>
          )}*/}
        </div>

        {/* This div is the "Actual Terminal" */}
        {/* style={{ width: "100vw", fontSize: "26px", lineHeight: "16px" }} */}
        <div style={{ width: "100vw", fontSize: "26px", lineHeight: "16px" }}>
          <Terminal
            className="terminal"
            name={"Round " + (roundNumber)}
            prompt={username + "\\" + terminalPrompt.join("\\") + ">"}
            colorMode={ColorMode.Dark}
            onInput={isLoading ? null : handleTerminalInput} //TIMER RUNNING? THEN FALSE ELSE handleTerminalInput
            startingInputValue={inputValue}
            height="80vh"
            paddingTop="75px"
          >
            {terminalLineData}
          </Terminal>
        </div>
      </div>
    </div>
  );
};

export default Game3;
