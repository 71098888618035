import React, { useEffect } from "react";

const SystemVulnerabilitiesRand = ({ roundNum, assignVulnerabilities }) => {
  useEffect(() => {
    assignVulnerabilities(systemVulnerabilities4EachRound[roundNum]);
  }, [roundNum]);

  const systemVulnerabilities4EachRound = {
    1: {
      System1: {
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 3],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium", 2],
        type_confusion: [126, "CWE-843", "type_confusion", "Low", 4],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "High", 1],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Low", 5],
      },
      System2: {
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Medium", 5],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Low", 3],
        path_traversal: [21, "CWE-22", "path_traversal", "Low", 4],
        use_after_free: [22, "CWE-416", "use_after_free", "High", 2],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium", 1],
      },
      System3: {
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low", 3],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium", 2],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "High", 1],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low", 4],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 5],
      },
      System4: {
        use_after_free: [22, "CWE-416", "use_after_free", "High", 2],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low", 3],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium", 1],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low", 5],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium", 4],
      },
      System5: {
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium", 3],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium", 5],
        type_confusion: [126, "CWE-843", "type_confusion", "Low", 1],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low", 4],
        use_after_free: [22, "CWE-416", "use_after_free", "High", 2],
      },
      System6: {
        type_confusion: [126, "CWE-843", "type_confusion", "Low", 5],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "High", 1],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium", 2],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low", 3],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 4],
      },
    },
    2: {
      System1: {
        sql_injection: [3306, "CWE-89", "sql_injection", "Low", 4],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium", 2],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 3],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low", 5],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "High", 1],
      },
      System2: {
        type_confusion: [126, "CWE-843", "type_confusion", "High", 3],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 4],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium", 2],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low", 5],
        path_traversal: [21, "CWE-22", "path_traversal", "Low", 1],
      },
      System3: {
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium", 5],
        type_confusion: [126, "CWE-843", "type_confusion", "Medium", 3],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low", 1],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low", 4],
        use_after_free: [22, "CWE-416", "use_after_free", "High", 2],
      },
      System4: {
        type_confusion: [126, "CWE-843", "type_confusion", "Low", 1],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low", 4],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 3],
        sql_injection: [3306, "CWE-89", "sql_injection", "High", 2],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium", 5],
      },
      System5: {
        path_traversal: [21, "CWE-22", "path_traversal", "Medium", 4],
        use_after_free: [22, "CWE-416", "use_after_free", "Low", 1],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low", 3],
        os_command_injection: [24, "CWE-78", "os_command_injection", "High", 2],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Medium", 5],
      },
      System6: {
        os_command_injection: [24, "CWE-78", "os_command_injection", "High", 2],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium", 1],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 3],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low", 4],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low", 5],
      },
    },
    3: {
      System1: {
        cross_site_scripting: [80, "CWE-79", "cross_site_scripting", "Medium", 4],
        type_confusion: [126, "CWE-843", "type_confusion", "Low", 1],
        sql_injection: [3306, "CWE-89", "sql_injection", "High", 5],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Medium", 2],
        use_after_free: [22, "CWE-416", "use_after_free", "Low", 3],
      },
      System2: {
        path_traversal: [21, "CWE-22", "path_traversal", "High", 2],
        use_after_free: [22, "CWE-416", "use_after_free", "Low", 1],
        buffer_overflow: [443, "CWE-122", "buffer_overflow", "Low", 4],
        cross_site_scripting: [80, "CWE-79", "cross_site_scripting", "Medium", 3],
        sql_injection: [3306, "CWE-89", "sql_injection", "Medium", 5],
      },
      System3: {
        type_confusion: [126, "CWE-843", "type_confusion", "Low", 4],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium", 1],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "High", 3],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 2],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Low", 5],
      },
      System4: {
        use_after_free: [22, "CWE-416", "use_after_free", "Low", 4],
        cross_site_scripting: [80, "CWE-79", "cross_site_scripting", "Low", 2],
        type_confusion: [126, "CWE-843", "type_confusion", "Medium", 3],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium", 1],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "High", 5],
      },
      System5: {
        sql_injection: [3306, "CWE-89", "sql_injection", "Medium", 2],
        use_after_free: [22, "CWE-416", "use_after_free", "High", 4],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium", 3],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Low", 1],
        type_confusion: [126, "CWE-843", "type_confusion", "Low", 5],
      },
      System6: {
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium", 4],
        cross_site_scripting: [80, "CWE-79", "cross_site_scripting", "Low", 2],
        path_traversal: [21, "CWE-22", "path_traversal", "Low", 3],
        os_command_injection: [24, "CWE-78", "os_command_injection", "High", 1],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 5],
      },
    },
    4: {
      System1: {
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium", 5],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low", 1],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low", 4],
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium", 3],
        use_after_free: [22, "CWE-416", "use_after_free", "High", 2],
      },
      System2: {
        sql_injection: [3306, "CWE-89", "sql_injection", "Low", 4],
        type_confusion: [126, "CWE-843", "type_confusion", "High", 1],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 5],
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "Medium", 2],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low", 3],
      },
      System3: {
        out_of_bounds_write: [22, "CWE-787", "out_of_bounds_write", "High", 1],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low", 5],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 2],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low", 3],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium", 4],
      },
      System4: {
        os_command_injection: [24, "CWE-78", "os_command_injection", "High", 2],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 5],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium", 3],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low", 4],
        type_confusion: [126, "CWE-843", "type_confusion", "Low", 1],
      },
      System5: {
        type_confusion: [126, "CWE-843", "type_confusion", "Low", 5],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low", 1],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 2],
        os_command_injection: [24, "CWE-78", "os_command_injection", "High", 3],
        path_traversal: [21, "CWE-22", "path_traversal", "Medium", 4],
      },
      System6: {
        os_command_injection: [24, "CWE-78", "os_command_injection", "Medium", 3],
        use_after_free: [22, "CWE-416", "use_after_free", "Medium", 2],
        type_confusion: [126, "CWE-843", "type_confusion", "High", 5],
        cross_site_scripting: [21, "CWE-79", "cross_site_scripting", "Low", 1],
        sql_injection: [3306, "CWE-89", "sql_injection", "Low", 4],
      },
    },
  };
  return <></>; //<div>SystemVulnerabilities</div>;
};

export default SystemVulnerabilitiesRand;
